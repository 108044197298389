import React from 'react'
import PageLayout from '../layouts/DefaultLayout'
import { FilteredMediaGrid } from '../components/media'
import ErrorComponent from '../components/ErrorComponent'
import useLibrary from '../hooks/useLibrary'

/**
 * Library Page.
 * @returns {function} Component
 */
const LibraryPage = () => {
  const {
    media,
    isLoading,
    error,
    sortMethod,
    currentPage,
    totalPages,
    tab,
    itemsPerPage,
    totalCount,
    updateSortMethod,
    updatePage,
    updateItemsPerPage,
    toggleTab,
  } = useLibrary()

  if (error) {
    return (
      <PageLayout>
        <ErrorComponent error={error} onReload={this.handleReload} />
      </PageLayout>
    )
  }

  return (
    <PageLayout className="LibraryPage">
      <p className="title">Library</p>
      <FilteredMediaGrid
        isLoading={isLoading}
        media={media}
        updateSortMethod={updateSortMethod}
        sortMethod={sortMethod}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        updatePage={updatePage}
        tab={tab}
        toggleTab={toggleTab}
        updateItemsPerPage={updateItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
    </PageLayout>
  )
}

export default LibraryPage
