import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './AuthorLinks.css'
import { authorUrl } from '../../../utils/routes'

/**
 * Formats a list of author objects into a list of links.
 *
 * @param {array} authors - List of author objects
 * @returns {function} Component
 */
const AuthorLinks = ({ authors = [] }) => {
  const linkClasses = classNames('MediaCardLink')

  if (authors && authors.length) {
    const authorList = authors.map((author, index) => {
      const { id, firstName, lastName } = author
      const fullName = `${firstName} ${lastName}`

      return (
        <>
          <Link
            to={authorUrl(id, fullName)}
            href={authorUrl(id, fullName)}
            className={linkClasses}
            key={fullName}
            style={{ color: 'black', marginLeft: '0.25rem' }}
          >
            {fullName}
          </Link>
          {authors.length > 1 && index < authors.length - 1 && ','}
        </>
      )
    })

    return authorList
  }

  return null
}

AuthorLinks.defaultProps = {
  authors: [],
}

AuthorLinks.propTypes = {
  authors: PropTypes.arrayOf({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}

export default AuthorLinks
