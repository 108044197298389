import * as sdk from '@deseretbook/media-sdk'
import { requestOptions } from './base'

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!! READ THIS !!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * I'm disabling documentation on these, because you should just go look at the sdk docs
 * to see what they do. These all just add requestOptions to the sdk.
 * Eventually we should refactor the sdk and these won't need to be a thing.
 */
/* eslint-disable require-jsdoc */

export const getMediaByAuthor = (params = {}) =>
  sdk.getMediaByAuthor(params, requestOptions())

export const getMediaByTopic = (params = {}) =>
  sdk.getMediaByTopic(params, requestOptions())

export const getSuggestions = (params = {}) =>
  sdk.getSuggestions(params, requestOptions())

export const searchAuthors = (params = {}) =>
  sdk.searchAuthors(params, requestOptions())

export const searchEbookContents = (params = {}) =>
  sdk.searchEbookContents(params, requestOptions())

export const searchEverything = (params = {}) =>
  sdk.searchEverything(params, requestOptions())

export const searchMediaTitles = (params = {}) =>
  sdk.searchMediaTitles(params, requestOptions())

export const searchTopics = (params = {}) =>
  sdk.searchTopics(params, requestOptions())
