import React, { createContext } from 'react'
import useSearch from '../../hooks/useSearch'

export const SearchContext = createContext({})

/**
 * The primary search provider for the application.
 *
 * @param {object} children - The children to render.
 *
 * @returns {object} searchResults, error, setQuery
 */
export const SearchProvider = ({ children }) => {
  const searchContent = useSearch('mediaTitle', { size: 10, loadAll: true })

  return <SearchContext.Provider value={searchContent}>{children}</SearchContext.Provider>
}
