import { getUser } from '../api/users'
import { BOOKSHELF_PLUS_IDS, DIGITAL_VIP_IDS } from '../subscriptionIds'

/**
 * checks if a user has a subscription or not
 *
 * @param {array} userSubIds - list of users subscriptions
 * @param {array} subscriptionIds - List of subscription ids to compare
 * @returns {boolean} Whether the user has a subscription or not
 */
const isUserSubscribed = (userSubIds = [], subscriptionIds = []) => {
  return userSubIds.some((id) => subscriptionIds.includes(id))
}

/**
 * React hook to check if a user has a subscription
 *
 * @returns {object} userPlus - whether the user has a plus subscription
 * @returns {object} userVip - whether the user has a vip subscription
 */
const useUserSub = () => {
  // Subscription IDs
  const plusIds = BOOKSHELF_PLUS_IDS
  const digitalVipIds = DIGITAL_VIP_IDS

  // User Subscriptions
  const { subscriptionPlanIds = [] } = getUser()
  const userPlus = isUserSubscribed(subscriptionPlanIds, plusIds)
  const userVip = isUserSubscribed(subscriptionPlanIds, digitalVipIds)

  return {
    userPlus,
    userVip,
  }
}

export default useUserSub
