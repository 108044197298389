/**
 * Formats the media list of authors into a comma separated string.
 *
 * @param {array} authors - list of authors
 *
 * @returns {string} comma separated list of authors
 */
const formatAuthorNames = (authors) => {
  if (authors && authors.length) {
    const authorList = authors.map((author) => {
      return `${author.firstName} ${author.lastName}`
    })

    return authorList.join(', ')
  }

  return ''
}

export default formatAuthorNames
