import React from 'react'
import PropTypes from 'prop-types'
import { HeadphonesIcon, StarIcon, IconWrapper, PlusLogo } from '../../../dbWebUI/icons/'
import './MediaCard.css'

/**
 * Displays additional icons on the media card.
 *
 * @params {object} props - component props
 *
 * @returns {function} Component
 */
const MediaCardIcon = ({ isAudiobook, isFavorite, isPlus }) => (
  <>
    {isAudiobook ? (
      <div className="badge audiobook has-shadow">
        <IconWrapper hasTextWhite>
          <HeadphonesIcon className="badge-icon" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
    {isFavorite ? (
      <div className="badge favorite has-shadow">
        <IconWrapper hasTextWhite>
          <StarIcon className="badge-icon" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
    {isPlus ? (
      <div className="badge plus has-shadow has-background-plus">
        <IconWrapper hasTextWhite className="plus-wrapper">
          <PlusLogo className="plus-badge" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
  </>
)

export default MediaCardIcon

MediaCardIcon.defaultProps = {
  isAudiobook: false,
  isPlus: false,
  isFavorite: false,
}

MediaCardIcon.propTypes = {
  isAudiobook: PropTypes.bool,
  isPlus: PropTypes.bool,
  isFavorite: PropTypes.bool,
}
