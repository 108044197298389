import React, { useContext, useEffect } from 'react'
import { Box, Divider, Select } from '@deseretbook/react-ui'
import PageLayout from '../layouts/DefaultLayout'
import ErrorComponent from '../components/ErrorComponent'
import MediaList from '../components/media/mediaList/MediaList'
import LoadingIndicator from '../components/media/loadingIndicator/LoadingIndicator'
import Paginator from '../components/buttons/Paginator'
import { SearchContext } from '../components/search/SearchProvider'

/**
 * Search page
 *
 * @returns {function} Component
 */
const SearchPage = () => {
  const {
    results,
    query,
    queryRef,
    setQuery,
    error,
    isLoading,
    isLoadingAll,
    pagination,
    itemsPerPage,
    setItemsPerPageCount,
    totalResults,
    updateMedia,
  } = useContext(SearchContext)

  const { updatePage, totalPages, currentPage } = pagination

  useEffect(() => {
    // Extract query parameter from URL
    const params = new URLSearchParams(window.location.search)
    const urlQuery = params.get('q')

    // Check if query parameter exists and is not empty
    if (urlQuery && urlQuery.trim() !== '' && query !== urlQuery) {
      // Set the query and trigger search
      setQuery(urlQuery)
    }
    // eslint-disable-next-line
  }, [location.search, setQuery])

  const resultStart = (currentPage - 1) * itemsPerPage + 1
  const resultEnd = Math.min(currentPage * itemsPerPage, totalResults)
  const showLoadingIndicator = isLoading || (currentPage > 1 && !results.length)

  let displayText = `Displaying ${resultStart} - ${resultEnd} of ${totalResults}`

  if (!isLoading && !isLoadingAll) {
    if (!results.length) {
      displayText = 'No results found.'
    }
  }

  return (
    <PageLayout>
      {error && <ErrorComponent error={error} />}
      <p className="title">{`Showing results for "${queryRef}" in All Categories`}</p>
      <Box alignItems="center" justifyContent="space-between">
        {!showLoadingIndicator ? <div>{displayText}</div> : null}
      </Box>
      <Divider />
      {showLoadingIndicator ? (
        <LoadingIndicator isRelative />
      ) : (
        <MediaList isSearch media={results} updateMedia={updateMedia} />
      )}

      {!showLoadingIndicator ? (
        <Box
          alignItems="center"
          justifyContent="space-between"
          style={{ margin: '1rem 0', padding: '1rem 0' }}
        >
          <Box alignItems="center">
            Show
            <Select
              style={{ padding: '0 2rem 0 0.5rem' }}
              value={itemsPerPage}
              options={[10, 20, 50]}
              removeHint
              fieldProps={{ style: { margin: '0 0.5rem' } }}
              onChange={(e) => {
                setItemsPerPageCount(e.target.value)
              }}
            />
            items per page
          </Box>
          <Box width={22}>
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={updatePage}
            />
          </Box>
        </Box>
      ) : null}
    </PageLayout>
  )
}

export default SearchPage
