import React from 'react'
import PropTypes from 'prop-types'
import { useResizeObserver } from '@deseretbook/react-utils'
import { withTheme, Button, Box, Level, Text } from '@deseretbook/react-ui'

/**
 * Slightly modified version of the `Button` component that maintains consistent height/width
 * because we know the contents will always just be a number.
 * @param {object} props - Other component props
 */
const PaginationButton = withTheme(({ theme, ...props }) => (
  <Button
    css={{
      height: theme.spacing.use(2.5),
      width: theme.spacing.use(2.5),
      padding: 0,
      opacity: 1,
    }}
    {...props}
  />
))

/**
 * Component for displaying pagination controls.
 *
 * @param {object} props - Component props
 * @returns {function} Paginator Component
 */
const Paginator = ({ currentPage = 0, totalPages = 0, setCurrentPage = () => {}, ...rest }) => {
  // Measure page button container so we can figure out how many buttons we can show
  const [innerContainerRef, contentWidth] = useResizeObserver()

  // Clamped totalPages value
  const currentPageValue = Math.min(totalPages, Math.max(1, currentPage))

  // Determine how much space we have for available page buttons.
  // We'll reserve 3.5rem for every Page button
  const maxNumberOfButtons = Math.floor(contentWidth / 20 / 3.5)

  // Create an initial array of page buttons to show. We'll limit it to the current page +/- 8
  // on each side. We'll clamp the results between 0 and the total number of pages so we don't
  // show buttons for any page that doesn't exist.
  let visiblePages = [
    ...[...Array(5).keys()]
      .map((number) => currentPageValue - number - 1)
      .filter((number) => number < currentPageValue)
      .reverse(),
    currentPageValue,
    ...[...Array(5).keys()].map((number) => currentPageValue + number + 1),
  ].filter((a) => a >= 1 && a <= totalPages)

  // We want the currentPage's button to be in the middle, so we figure out how many buttons we
  // have room for on each side. We'll also actually limit it so we have at most 9 buttons visible.
  const countEachSide = Math.min(Math.floor((maxNumberOfButtons - 1) / 2), 4)

  // We need to figure out where in the list of visible pages the current page sits.
  const indexOfCurrentPage = visiblePages.findIndex((page) => page === currentPageValue)

  // Limit the visible pages based on how much room we have.
  const numberOfPagesToAddOnRight = Math.max(0, countEachSide - indexOfCurrentPage)
  const numberOfPagesToAddOnLeft = Math.max(
    0,
    countEachSide - (visiblePages.length - indexOfCurrentPage) + 1,
  )

  visiblePages = visiblePages.slice(
    Math.max(0, indexOfCurrentPage - countEachSide - numberOfPagesToAddOnLeft),
    Math.min(totalPages, indexOfCurrentPage + countEachSide + 1 + numberOfPagesToAddOnRight),
  )

  // We want to provide a way for users to quickly jump to first/last pages, so we need to figure
  // out if we should add an ellipsis between the visiblePages and the first/last page buttons.
  const hasLeftEllipsis = visiblePages.length > 4 && visiblePages[0] > 1
  const hasRightEllipsis =
    visiblePages.length > 4 && visiblePages[visiblePages.length - 1] < totalPages

  const hasPreviousPage = currentPageValue > 1
  const hasNextPage = currentPageValue < totalPages

  // If we show the ellipsis on the left, then we need to make extra space for it.
  if (hasLeftEllipsis) {
    visiblePages = visiblePages.slice(1, visiblePages.length)
  }

  // If we show the ellipsis on the right, then we need to make extra space for it.
  if (hasRightEllipsis) {
    visiblePages = visiblePages.slice(0, visiblePages.length - 1)
  }

  return (
    <Box
      marginVertical={1}
      ref={innerContainerRef}
      maxWidth={50}
      css={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      {totalPages > 1 && (
        <nav role="navigation" aria-label="Pagination Navigation" {...rest}>
          <Level>
            <Level.Left>
              <Level.Item>
                <Button
                  type="button"
                  icon="chevronLeft"
                  color="light"
                  aria-label={`Go to Page ${Math.max(1, currentPageValue - 1)}`}
                  disabled={!hasPreviousPage}
                  style={{ opacity: 1 }}
                  onClick={() => setCurrentPage(Math.max(1, currentPageValue - 1))}
                />
              </Level.Item>
            </Level.Left>
            <Level.Item>
              <Box justifyContent="center" alignItems="center" width="100%">
                {hasLeftEllipsis && (
                  <React.Fragment>
                    <Box marginHorizontal={0.25}>
                      <PaginationButton
                        type="button"
                        color="light"
                        aria-label="Go to Page 1"
                        style={{ opacity: 1 }}
                        onClick={() => setCurrentPage(1)}
                      >
                        1
                      </PaginationButton>
                    </Box>
                    <Box marginHorizontal={0.25} alignItems="center">
                      <Text css={{ letterSpacing: '4px' }} color="muted">
                        ...
                      </Text>
                    </Box>
                  </React.Fragment>
                )}
                {/*
                  If no pages are visible, realistically that means the width hasn't been calculated,
                  so we don't want to render anything quite yet until we know which middle content
                  will work best.
                */}
                {maxNumberOfButtons < 5 && maxNumberOfButtons > 0 && (
                  <Text>
                    {currentPage} of {totalPages}
                  </Text>
                )}
                {maxNumberOfButtons >= 5 &&
                  visiblePages.map((number) => (
                    <Box key={number} marginHorizontal={0.25}>
                      <PaginationButton
                        type="button"
                        color={currentPageValue === number ? 'primary' : 'light'}
                        onClick={() => setCurrentPage(number)}
                        disabled={currentPageValue === number}
                        style={{ opacity: 1 }}
                        aria-label={
                          currentPageValue === number
                            ? `Current Page, Page ${currentPageValue}`
                            : `Go to Page ${currentPageValue}`
                        }
                        aria-current={currentPageValue === number}
                      >
                        {number}
                      </PaginationButton>
                    </Box>
                  ))}
                {hasRightEllipsis && (
                  <React.Fragment>
                    <Box marginHorizontal={0.25} alignItems="center">
                      <Text css={{ letterSpacing: '4px' }} color="muted">
                        ...
                      </Text>
                    </Box>
                    <Box marginHorizontal={0.25}>
                      <PaginationButton
                        type="button"
                        color="light"
                        aria-label={`Go to Page ${totalPages}`}
                        style={{ opacity: 1 }}
                        onClick={() => setCurrentPage(totalPages)}
                      >
                        {totalPages}
                      </PaginationButton>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Level.Item>
            <Level.Right>
              <Level.Item>
                <Button
                  type="button"
                  icon="chevronRight"
                  color="light"
                  onClick={() => setCurrentPage(Math.min(totalPages, currentPageValue + 1))}
                  aria-label={`Go to Page ${Math.min(totalPages, currentPageValue + 1)}`}
                  disabled={!hasNextPage}
                  style={{ opacity: 1 }}
                />
              </Level.Item>
            </Level.Right>
          </Level>
        </nav>
      )}
    </Box>
  )
}

Paginator.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  setCurrentPage: () => {},
}

Paginator.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default Paginator
