import React, { useEffect, useState } from 'react'
import { Box, Divider, Select } from '@deseretbook/react-ui'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import PageLayout from '../layouts/DefaultLayout'
import ErrorComponent from '../components/ErrorComponent'
import MediaList from '../components/media/mediaList/MediaList'
import LoadingIndicator from '../components/media/loadingIndicator/LoadingIndicator'
import Paginator from '../components/buttons/Paginator'
import useSearch from '../hooks/useSearch'

/**
 * Search page
 *
 * @returns {function} Component
 */
const AuthorSearchPage = ({ match }) => {
  const [authorId, setAuthorId] = useState(match.params.id)

  const url = new URL(window.location.href)
  const name = url.searchParams.get('name')

  const {
    results,
    setQuery,
    error,
    isLoading,
    isLoadingAll,
    pagination,
    itemsPerPage,
    setItemsPerPageCount,
    totalResults,
    setIsLoading,
    updateMedia,
  } = useSearch('author', { authorId, loadAll: true })

  const { updatePage, totalPages, currentPage } = pagination

  const location = useLocation()

  useEffect(() => {
    // Extract query parameter from URL
    const params = new URLSearchParams(location.search)
    const urlQuery = params.get('q')

    // Check if query parameter exists and is not empty
    if (urlQuery && urlQuery.trim() !== '') {
      // Set the query and trigger search
      setQuery(urlQuery)
    }
  }, [location.search, setQuery])

  useEffect(() => {
    if (match.params.id !== authorId) {
      setAuthorId(match.params.id)
      setIsLoading(true)
    }
    // eslint-disable-next-line
  }, [match.params.id])

  if (error) {
    return (
      <PageLayout>
        <ErrorComponent error={error} />
      </PageLayout>
    )
  }

  const resultStart = (currentPage - 1) * itemsPerPage + 1
  const resultEnd = Math.min(currentPage * itemsPerPage, totalResults)
  const showLoadingIndicator = isLoading || (currentPage > 1 && !results.length)

  let displayText = `Displaying ${resultStart} - ${resultEnd} of ${totalResults}`

  if (!isLoading && !isLoadingAll) {
    if (!results.length) {
      displayText = 'No results found.'
    }
  }

  return (
    <PageLayout>
      <p className="title">{`All books by "${name}"`}</p>
      <Box alignItems="center" justifyContent="space-between">
        {!showLoadingIndicator ? <div>{displayText}</div> : null}
      </Box>
      <Divider />
      {showLoadingIndicator ? (
        <LoadingIndicator isRelative />
      ) : (
        <MediaList isSearch media={results} updateMedia={updateMedia} />
      )}

      {!showLoadingIndicator ? (
        <Box
          alignItems="center"
          justifyContent="space-between"
          style={{ margin: '1rem 0', padding: '1rem 0' }}
        >
          <Box alignItems="center">
            Show
            <Select
              style={{ padding: '0 2rem 0 0.5rem' }}
              value={itemsPerPage}
              options={[10, 20, 50]}
              removeHint
              fieldProps={{ style: { margin: '0 0.5rem' } }}
              onChange={(e) => {
                setItemsPerPageCount(e.target.value)
              }}
            />
            items per page
          </Box>
          <Box width={22}>
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={updatePage}
            />
          </Box>
        </Box>
      ) : null}
    </PageLayout>
  )
}

AuthorSearchPage.defaultProps = {
  match: { params: { id: '' } },
}

AuthorSearchPage.propTypes = {
  /** Match object from React Router */
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

export default AuthorSearchPage
