import React from 'react'
import { Box, Icon, Text } from '@deseretbook/react-ui'
import PropTypes from 'prop-types'

/**
 * In Library Badge used to signify if a user has an item in their library
 *
 * @param {boolean} isPlus - Whether the user has a plus subscription
 * @param {boolean} isVip - Whether the user has a vip subscription
 * @param {boolean} purchased - Whether the user has purchased the item
 *
 * @returns {function} Component
 */
const InLibraryBadge = ({ isPlus, isVip, purchased, isFree }) => {
  let color = 'white'
  let backgroundColor = '#00d1b2'

  if (!isFree && !purchased) {
    if (isVip) {
      color = 'white'
      backgroundColor = '#09668a'
    } else if (isPlus) {
      color = 'black'
      backgroundColor = '#f7a92b'
    }
  }

  return (
    <Box marginLeft={1.5} alignItems="center">
      <Icon
        name="check"
        size="small"
        color="dark"
        shade="dark"
        style={{
          color,
          backgroundColor,
          borderRadius: '50px',
          height: '1.25rem',
          width: '1.25rem',
        }}
      />
      <Text as="span" style={{ marginLeft: '0.5rem' }}>
        In Library
      </Text>
    </Box>
  )
}

InLibraryBadge.defaultProps = {
  isPlus: false,
  isVip: false,
  isFree: false,
  purchased: false,
}

InLibraryBadge.propTypes = {
  purchased: PropTypes.bool,
  isPlus: PropTypes.bool,
  isVip: PropTypes.bool,
  isFree: PropTypes.bool,
}

export default InLibraryBadge
