import React from 'react'
import { Button } from '@deseretbook/react-ui'
import propTypes from 'prop-types'
import addMediaToLibrary from '../../api/media/addMediaToLibrary'

/**
 * Add to Library button that redirects to dbcom purchase page
 *
 * @param {object} props - component props
 * @param {string} props.linkClasses - Additional classes to
 *
 * @returns {function} Component
 */
const AddToLibrary = ({
  isFree,
  isVip,
  isPlus,
  linkClasses,
  mediaId,
  sku,
  updateMedia,
  ...rest
}) => {
  let buttonColor = 'primary'

  if (isFree) {
    buttonColor = 'primary'
  } else if (isVip) {
    buttonColor = 'vip'
  } else if (isPlus) {
    buttonColor = 'plus'
  }

  /**
   * Handles updating the media object returned from the API
   */
  const handleUpdateMedia = async () => {
    try {
      const addedMedia = await addMediaToLibrary({ id: mediaId, sku })

      if (addedMedia) {
        updateMedia(addedMedia, mediaId, sku)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Button
      className={linkClasses}
      color={buttonColor}
      style={{ width: '10rem' }}
      onClick={handleUpdateMedia}
      {...rest}
    >
      Add to Library
    </Button>
  )
}

AddToLibrary.defaultProps = {
  linkClasses: '',
  isFree: false,
  isVip: false,
  isPlus: false,
  mediaId: '',
  sku: '',
  updateMedia: () => {},
}

AddToLibrary.propTypes = {
  linkClasses: propTypes.string,
  isFree: propTypes.bool,
  isVip: propTypes.bool,
  isPlus: propTypes.bool,
  mediaId: propTypes.string,
  sku: propTypes.string,
  updateMedia: propTypes.func,
}

export default AddToLibrary
