import { useState, useEffect } from 'react'
import { getLibrary } from '../api/media'
import { SORT_OPTIONS, TABS } from '../components/media/constants'
import updatePageParam from '../utils/updatePageParam'

const defaultMediaSort = localStorage.getItem('mediaSort') || SORT_OPTIONS.recentlyAdded
const defaultMediaType = localStorage.getItem('mediaType') || TABS.ebook
const defaultPageSize = localStorage.getItem('itemsPerPage') || 42

// eslint-disable-next-line
const useLibrary = () => {
  // Get current page from URL query params
  const defaultPage = Number(new URLSearchParams(window.location.search).get('page')) || 1

  const [tab, setTab] = useState(defaultMediaType)
  const [itemsPerPage, setItemsPerPage] = useState(defaultPageSize)
  const [media, setMedia] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [totalPages, setTotalPages] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [error, setError] = useState(null)
  const [sortMethod, setSortMethod] = useState(defaultMediaSort)
  const [sortOptions, setSortOptions] = useState({ orderBy: 'added', orderDirection: 'desc' })

  // Search Cache
  const [searchCache, setSearchCache] = useState({})
  const [cacheLoading, setCacheLoading] = useState(false)

  /**
   * Returns the sorting options for the user library.
   * @param {string} newSortMethod - Method matching the SORT_OPTIONS constant.
   * @returns {object} - The sorting options.
   */
  const getSortOptions = (newSortMethod = sortMethod) => {
    const newSortOptions = { orderBy: 'added', orderDirection: 'desc' }

    if (newSortMethod === SORT_OPTIONS.title) {
      newSortOptions.orderBy = 'title'
      newSortOptions.orderDirection = 'asc'
    } else if (newSortMethod === SORT_OPTIONS.titleZ) {
      newSortOptions.orderBy = 'title'
    } else if (newSortMethod === SORT_OPTIONS.author) {
      newSortOptions.orderBy = 'author'
      newSortOptions.orderDirection = 'asc'
    } else if (newSortMethod === SORT_OPTIONS.authorZ) {
      newSortOptions.orderBy = 'author'
    }

    return newSortOptions
  }

  /**
   * Updates library sorting methods. The user library search does not have
   * the ability to search by recently read, or by publication date.
   * Call another method to manually sort this way until the API is updated.
   *
   * @param {string} newSortMethod - Method matching the SORT_OPTIONS constant.
   */
  const updateSortMethod = (newSortMethod) => {
    localStorage.setItem('mediaSort', newSortMethod)
    const newSortOptions = getSortOptions(newSortMethod)

    setMedia([])
    setSearchCache({})
    setCurrentPage(1)
    setSortMethod(newSortMethod)
    setSortOptions(newSortOptions)
    setIsLoading(true)
  }

  /**
   * Fetches the user library!
   * @param {string} newTab - The tab to fetch
   * @param {number} newItemsPerPage - The number of items per page
   * @param {number} page - The page number to fetch
   */
  const fetchUserLibrary = async () => {
    let librarySearchOptions = sortOptions

    if (sortMethod !== SORT_OPTIONS.recentlyAdded) {
      librarySearchOptions = getSortOptions(sortMethod)
    }

    const { orderBy, orderDirection } = librarySearchOptions

    const url = new URL(window.location.href).pathname || ''
    const isLibraryPage = url.includes('/library') || url === '/'

    if (isLibraryPage) {
      updatePageParam(currentPage)
    }

    try {
      let response = {}

      if (searchCache && searchCache[currentPage]) {
        response = searchCache[currentPage]
      } else {
        /**
         * If it's the first page, get the next three pages.
         * If it's a middle page, get the page before and after.
         * If it's the last page, get the previous three pages.
         */
        response = await getLibrary({
          page: currentPage - 1,
          perPage: itemsPerPage,
          orderBy, // Can be: added, title, author, favorite
          orderDirection, // Can be: asc, desc
          filterBy: [tab],
        })
      }

      const newMedia = response.results

      const newTotalCount = response && response.pagination && response.pagination.total

      setSearchCache({ ...searchCache, [currentPage]: response })

      setMedia(newMedia)
      setIsLoading(false)
      setTotalPages(response.pagination.lastPage + 1)
      setTotalCount(newTotalCount)
      setCacheLoading(true)
    } catch (e) {
      console.error(e)
      setError(e)
    }
  }

  /**
   * Fetches additional pages to the cache
   * @param {array} pages - The pages to fetch
   */
  const fetchNeighborPages = async (pages) => {
    let librarySearchOptions = sortOptions

    if (sortMethod !== SORT_OPTIONS.recentlyAdded) {
      librarySearchOptions = getSortOptions(sortMethod)
    }

    const { orderBy, orderDirection } = librarySearchOptions

    try {
      const fetchPromises = pages.map(async (page) => {
        if (!searchCache[page]) {
          const response = await getLibrary({
            page: page - 1,
            perPage: itemsPerPage,
            orderBy, // Can be: added, title, author, favorite
            orderDirection, // Can be: asc, desc
            filterBy: [tab],
          })

          return { page, response }
        }

        return null // Page is already cached
      })

      const results = await Promise.all(fetchPromises)

      // Update cache with fetched pages
      const newCache = {}

      results.forEach((result) => {
        if (result) {
          newCache[result.page] = result.response
        }
      })

      setSearchCache((prevCache) => ({ ...prevCache, ...newCache }))
      setCacheLoading(false)
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  /**
   * Updates the current page number.
   * @param {number} page - The page number to update to.
   */
  const updatePage = (page) => {
    setCurrentPage(page)
    setMedia([])
    setIsLoading(true)
  }

  /**
   * Update items per page
   * @param {number} newItemsPerPage - The number of items per page
   */
  const updateItemsPerPage = (newItemsPerPage) => {
    localStorage.setItem('itemsPerPage', newItemsPerPage)

    setMedia([])
    setSearchCache({})
    setCurrentPage(1)
    setItemsPerPage(newItemsPerPage)
    setIsLoading(true)
  }

  /**
   * Returns media by page.
   * @param {array} results - The results to paginate.
   * @returns {array} - The paginated results.
   */
  // const mediaByPage = (results) => {
  //   const startIndex = (currentPage - 1) * itemsPerPage
  //   const paginated = results.slice(startIndex, startIndex + itemsPerPage)

  //   return paginated
  // }

  /**
   * Changes the current tab
   * @param {tab} newTab Tab to open
   */
  const toggleTab = (newTab) => {
    localStorage.setItem('mediaType', newTab)
    setMedia([])
    setSearchCache({})
    setCurrentPage(1)
    setTab(newTab)
    setIsLoading(true)
  }

  if (error) {
    console.log(error)
  }

  useEffect(() => {
    if (isLoading) {
      fetchUserLibrary()
    }
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    if (cacheLoading) {
      const neighborPages = []

      if (currentPage > 1 && !searchCache[currentPage - 1]) {
        neighborPages.push(currentPage - 1)
      }

      if (currentPage < totalPages && !searchCache[currentPage + 1]) {
        neighborPages.push(currentPage + 1)
      }

      if (neighborPages.length > 0) {
        fetchNeighborPages(neighborPages)
      }
    }
    // eslint-disable-next-line
  }, [cacheLoading])

  return {
    media,
    isLoading,
    error,
    sortMethod,
    updateSortMethod,
    updatePage,
    updateItemsPerPage,
    toggleTab,
    currentPage,
    totalPages,
    tab,
    itemsPerPage,
    totalCount,
  }
}

export default useLibrary
