import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import PublicRoute from './components/routes/PublicRoute'
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'

import LoginPage from './pages/users/LoginPage'
import LogoutPage from './pages/users/LogoutPage'
import RegisterPage from './pages/users/RegisterPage'
import LibraryPage from './pages/LibraryPage'
import ReaderPage from './pages/ReaderPage'
import LandingPage from './pages/LandingPage'
import AudioBookListener from './pages/ListeningPage'
import PageNotFound from './pages/PageNotFound'
import BookshelfThemeProvider from './themes/default/bookshelfThemeProvider'
import { registerIcons } from './utils/base'
import SearchPage from './pages/SearchPage'
import AuthorSearchPage from './pages/AuthorSearchPage'
import { SearchProvider } from './components/search/SearchProvider'

registerIcons()

/**
 * Bookshelf Web App Routes.
 *
 * @returns {function} Component
 */
function Routes() {
  return (
    <BrowserRouter>
      <BookshelfThemeProvider>
        <SearchProvider>
          <Switch>
            <AuthenticatedRoute path="/" exact component={LibraryPage} />
            <AuthenticatedRoute path="/library" component={LibraryPage} />
            <AuthenticatedRoute path="/media/:id/read/:sku" exact component={ReaderPage} />
            <AuthenticatedRoute path="/media/:id/listen/:sku" exact component={AudioBookListener} />
            <AuthenticatedRoute path="/search" component={SearchPage} />
            <AuthenticatedRoute path="/author/:id" component={AuthorSearchPage} />
            <AuthenticatedRoute path="/media/:id/details/:sku" exact component={LandingPage} />
            <PublicRoute path="/login" component={LoginPage} />
            <PublicRoute path="/logout" component={LogoutPage} />
            <PublicRoute path="/register" component={RegisterPage} />
            <PublicRoute component={PageNotFound} />
          </Switch>
        </SearchProvider>
      </BookshelfThemeProvider>
    </BrowserRouter>
  )
}

export default Routes
