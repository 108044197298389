/**
 * Updates the page param in the URL.
 * @param {number} page - The page number to update to.
 */
const updatePageParam = (page) => {
  // Set the currentPage in localStorage
  const params = new URLSearchParams(window.location.search)

  params.set('page', page)
  window.history.pushState({}, '', `${window.location.pathname}?${params}`)
}

export default updatePageParam
