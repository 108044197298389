import { camelCase } from 'changecase-objects'
import { requestOptions } from '../base'

/**
 * Attempts to add media object to the user's library
 *
 * @param {object} params - user favorite request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - product sku
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default async function addMediaToLibrary(params = {}) {
  const { baseUrl, token, deviceId, headers } = requestOptions()

  try {
    // Adds media to user's library
    const response = await fetch(
      `${baseUrl}/subscribed_items?t=${token}&device_id=${deviceId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: JSON.stringify(params),
      },
    )

    // If the request is successful, return the media object
    if (response.ok) {
      const data = await response.json()

      const formatted = camelCase(data)

      return formatted && formatted.media
    }
    throw new Error('Failed to add media to library')
  } catch (e) {
    return null
  }
}
