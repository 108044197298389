import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Title, Box, Button, CMSContent, Icon } from '@deseretbook/react-ui'
import './MediaCard.css'
import MediaCard from './MediaCard'
import getMediaUrl from '../../../utils/mediaUrl'
import { detailsUrl } from '../../../utils/routes'
import PlayerWrapper from './PlayerWrapper'
import trimHtml from '../../../utils/trimHtml'
import formatAuthorNames from '../../../utils/formatAuthorNames'

/**
 * A component for displaying a media object.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function MediaCardFull(props) {
  const { className, media, mediaType, isAudiobook, isPlus, ...rest } = props

  if (!media) {
    return false
  }

  // Applies flexbox styles to arrange figure and descriptions
  const mediaCardContainer = classNames('MediaCardContainer', className)
  // Applies base styling to ensure figure dimensions
  const mediaCardItem = classNames('MediaCardItem')
  // Adds underline to links
  const titleClasses = classNames('MediaCardTitle', className)
  const linkClasses = classNames('MediaCardLink', className)

  const landingPage = detailsUrl(media.mediaId, media[mediaType] && media[mediaType].sku)

  const titleInfo = (
    <Title
      isPaddingless
      title={media && media.title}
      titleProps={{ style: { marginBottom: '0.25rem' } }}
      subtitle={media && media.authors.length && formatAuthorNames(media.authors)}
      subtitleProps={{ style: { fontSize: '1rem', color: '#4a4a4a' } }}
    />
  )

  const limitedDescription = trimHtml(media.description, 500)

  return (
    <>
      <div className={mediaCardContainer} {...rest}>
        <MediaCard {...props} className={mediaCardItem} showOptions={false} isPlus={false} />

        <Box alignItems="start" justifyContent="start" isColumn isFullwidth>
          <Box alignItems="start" justifyContent="space-between" isFullwidth>
            <PlayerWrapper
              isAudiobook={isAudiobook}
              media={media}
              className={titleClasses}
              style={{ width: '100%', cursor: 'pointer' }}
              to={getMediaUrl(media)}
              {...rest}
            >
              <Box alignItems="start" justifyContent="start">
                {titleInfo}
                {isPlus && (
                  <Icon
                    style={{
                      width: '7rem',
                      minWidth: '7rem',
                      height: '1.25rem',
                      marginLeft: '0.75rem',
                      display: 'inline-block',
                    }}
                    name="plus"
                  />
                )}
              </Box>
            </PlayerWrapper>
            <Button
              as={Link}
              to={landingPage}
              className={linkClasses}
              style={{ width: '10rem' }}
              {...rest}
            >
              View Details
            </Button>
          </Box>
          <Box paddingTop={1} maxHeight={10}>
            <CMSContent
              dangerouslySetInnerHTML={{ __html: limitedDescription }}
              itsNotDangerousBecause="media.description comes directly from the Bookshelf API, and should be trustworthy"
            />
          </Box>
        </Box>
      </div>
    </>
  )
}

MediaCardFull.defaultProps = {
  className: '',
  media: {},
  mediaType: 'ebook',
  isAudiobook: false,
  isPlus: false,
}

MediaCardFull.propTypes = {
  /** Additional classes */
  className: PropTypes.string,
  /** Media object to be rendered */
  media: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    authors: PropTypes.shape,
    mediaId: PropTypes.string,
  }),
  /** The type of media to access */
  mediaType: PropTypes.string,
  /** If the media is an audiobook */
  isAudiobook: PropTypes.bool,
  /** If the media is part of bookshelf+ */
  isPlus: PropTypes.bool,
}

export default MediaCardFull
