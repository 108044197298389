import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getUser, isAuthenticated } from '../api/users'
import { Container, Navbar, SimpleSidebar, Menu, Hero } from '../dbWebUI'
import { libraryUrl, loginUrl, logoutUrl } from '../utils/routes'

import DesktopLogo from './images/logo-desktop.svg'
import MobileLogo from './images/logo-mobile.svg'
import SearchBar from './search/SearchBar'

/**
 * Main header component.
 */
class MainHeader extends Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.state = {
      isOpen: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  /**
   * Toggles the visibility of the menu on small sized screens.
   *
   * @param {boolean} [isOpen=true] - If the menu should be open or closed
   */
  toggleMenu(isOpen = true) {
    this.setState({ isOpen })
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { className, isFixed, hasScrollToggle, navbarProps, ...rest } = this.props
    const { isOpen } = this.state

    // Mobile sidebar menu
    const sidebar = (
      <SimpleSidebar isOpen={isOpen} onClose={() => this.toggleMenu(false)}>
        <SimpleSidebar.Content>
          <Hero isPrimary>
            <Hero.Body hasTextCentered>
              <figure className="image">
                <img src={DesktopLogo} alt="Deseret Bookshelf" />
              </figure>
            </Hero.Body>
          </Hero>
          <Menu>
            <Menu.List>
              <Menu.ListItem>
                <Menu.ListItem.Link to={libraryUrl()}>Library</Menu.ListItem.Link>
              </Menu.ListItem>
              <Menu.ListItem>
                <a href="mailto:downloads@deseretbook.com">Give Feedback</a>
              </Menu.ListItem>
              <Menu.ListItem>
                {!isAuthenticated() ? (
                  <Menu.ListItem.Link to={loginUrl()}>Sign in</Menu.ListItem.Link>
                ) : (
                  <Menu.ListItem.Link to={logoutUrl()}>Sign out</Menu.ListItem.Link>
                )}
              </Menu.ListItem>
            </Menu.List>
          </Menu>
        </SimpleSidebar.Content>
      </SimpleSidebar>
    )

    const classes = classNames('MainHeader', className)

    const legalLinksArr = [
      {
        title: 'Contact Us',
        href: 'https://support.deseretbook.com/article/5-contact-deseret-book',
      },
      {
        title: 'Questions & Support',
        href: 'https://support.deseretbook.com/',
      },
      {
        title: 'Terms of Use',
        href: 'https://www.deseretbook.com/terms-of-use.html',
      },
      {
        title: 'Privacy Policy',
        href: 'https://www.deseretbook.com/privacy.html',
      },
      {
        title: 'Legal',
        href: 'https://www.deseretbook.com/legal.html',
      },
    ]

    const legalLinks = (
      <>
        {legalLinksArr.map(link => (
          <Navbar.OutsideLink
            target="_blank"
            rel="noopener noreferrer"
            href={link.href}
          >
            {link.title}
          </Navbar.OutsideLink>
        ))}
      </>
    )

    return (
      <header className={classes} {...rest}>
        <Navbar
          isFixedTop={isFixed}
          hasScrollToggle={hasScrollToggle}
          hasShadow
          isPrimary
          {...navbarProps}
        >
          <Container>
            <Navbar.Brand>
              <Navbar.Link to={libraryUrl()} activeClassName="">
                <img src={MobileLogo} alt="Deseret Bookshelf" className="is-hidden-tablet" />
                <img src={DesktopLogo} alt="Deseret Bookshelf" className="is-hidden-mobile" />
              </Navbar.Link>
              <Navbar.Burger isActive={isOpen} onClick={this.toggleMenu} />
            </Navbar.Brand>
            <Navbar.Menu>
              <Navbar.Start>
                <Navbar.Link to={libraryUrl()}>Library</Navbar.Link>
              </Navbar.Start>
              <Navbar.End>
                {isAuthenticated() ? (
                  <Navbar.StaticItem>
                    <SearchBar />
                  </Navbar.StaticItem>
                ) : null}
                {!isAuthenticated() ? (
                  <>
                    <Navbar.Link to={loginUrl()}>Sign in</Navbar.Link>
                  </>
                ) : (
                  <Navbar.Dropdown isHoverable>
                    <Navbar.Dropdown.Label>{getUser().name}</Navbar.Dropdown.Label>
                    <Navbar.Dropdown.Menu isRight>
                      <Navbar.OutsideLink
                        href={`${process.env.REACT_APP_DESERET_BOOK_BASE_URL}/account/subscriptions`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Manage Subscription
                      </Navbar.OutsideLink>
                      {legalLinks}
                      <Navbar.Link to={logoutUrl()}>Sign out</Navbar.Link>
                    </Navbar.Dropdown.Menu>
                  </Navbar.Dropdown>
                )}
              </Navbar.End>
            </Navbar.Menu>
          </Container>
        </Navbar>
        {sidebar}
      </header>
    )
  }
}

MainHeader.defaultProps = {
  hasScrollToggle: true,
  isFixed: true,
  className: '',
  navbarProps: {},
}

MainHeader.propTypes = {
  /** if the navbar should hide while scrolling down and display while scrolling up */
  hasScrollToggle: PropTypes.bool,
  /** If the navbar should be fixed to the top of the screen */
  isFixed: PropTypes.bool,
  /** Additional class names. */
  className: PropTypes.string,
  /** Props to pass to the primary `<Navbar />` component */
  navbarProps: PropTypes.shape({}),
}

export default MainHeader
