import { useState, useEffect } from 'react'
import { getMedia } from '../api/media'

/**
 * Fetches media from API
 *
 * @param {number} mediaId id of media to fetch
 * @returns {object} isLoading, error and media items
 */
const useMedia = (mediaId) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [media, setMedia] = useState({})

  /**
   * Fetches media from the API
   */
  const fetchMedia = async () => {
    try {
      const newMedia = await getMedia({ id: mediaId })

      setMedia(newMedia)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // Fetch data when the component gets setup.
    if (isLoading) {
      fetchMedia(mediaId)
    }
    // eslint-disable-next-line
  }, [isLoading, mediaId])

  return { isLoading, error, media, setMedia }
}

export default useMedia
