import React, { useContext, useEffect, useState } from 'react'
import { Box, CMSContent, Divider, Text, Button, Icon } from '@deseretbook/react-ui'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@deseretbook/react-utils'
import PageLayout from '../layouts/DefaultLayout'
import ErrorComponent from '../components/ErrorComponent'
import LoadingIndicator from '../components/media/loadingIndicator/LoadingIndicator'
import { readEbookUrl, listenAudiobookUrl } from '../utils/routes'
import { openPopupPlayer } from '../utils/media'
import useMedia from '../hooks/useMedia'
import LearnMoreButton from '../components/buttons/LearnMoreButton'
import ActNowButton from '../components/buttons/ActNowButton'
import SocialLinks from '../components/media/socialLinks/SocialLinks'
import { formatDuration } from '../utils/base'
import { getAudiobookManifest } from '../api/media'
import InLibraryBadge from '../components/media/inLibraryBadge/InLibraryBadge'
import checkMediaSubscription from '../utils/checkMediaSub'
import useUserSub from '../hooks/useUserSub'
import { BOOKSHELF_PLUS_IDS, DIGITAL_VIP_IDS } from '../subscriptionIds'
import AddToLibrary from '../components/buttons/AddToLibrary'
import AuthorLinks from '../components/media/authorLinks/AuthorLinks'
import { SearchContext } from '../components/search/SearchProvider'

/**
 * Landing page for Media items
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
const LandingPage = ({ match }) => {
  const { id, sku } = match.params
  const { updateMedia } = useContext(SearchContext)
  const { isLoading, error, media, setMedia } = useMedia(id)
  const [audiobook, setAudiobook] = useState(null)
  const { userPlus, userVip } = useUserSub()
  const isMediumOrLarger = useMediaQuery(850)
  // Set media types
  const isEbook = media.ebook && media.ebook.sku === sku
  const isAudiobook = media.audiobook && media.audiobook.sku === sku

  const plusIds = BOOKSHELF_PLUS_IDS
  const vipIds = DIGITAL_VIP_IDS

  /**
   * Fetches the audiobook manifest
   */
  const getAudiobook = async () => {
    try {
      const audiobookResults = await getAudiobookManifest({ id, sku })

      setAudiobook(audiobookResults)
    } catch (e) {
      setAudiobook(null)
    }
  }

  // Get the audiobook manifest if the media type is audiobook
  // This is primarily to display the duration
  useEffect(() => {
    if (isAudiobook && !audiobook) {
      getAudiobook()
    }
    // eslint-disable-next-line
  }, [isAudiobook])

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (error) {
    return (
      <PageLayout>
        <ErrorComponent error={error} />
      </PageLayout>
    )
  }

  // Collection of variables to be used in the component
  const accessor = isEbook ? 'ebook' : 'audiobook'
  const isPlus = checkMediaSubscription(media[accessor], plusIds)
  const isVip = checkMediaSubscription(media[accessor], vipIds)
  const isFree = (media[accessor].subscriptionPlanIds || []).includes('free')

  const { inLibrary = false, subscribed = false, purchased = false, subscribable = false } =
    media[accessor] && media[accessor].userInfo

  /**
   * Handles updating the media object returned from the API
   *
   * @param {object} newMedia - The updated media object.
   * @param {number} mediaId - The id of the media to match.
   * @param {string} mediaSku - The sku of the media to match.
   */
  const handleUpdateMedia = (newMedia, mediaId, mediaSku) => {
    const formattedMedia = Object.assign({}, newMedia)

    // Remove ebook or audiobook from media object
    if (accessor === 'ebook') {
      formattedMedia.audiobook = null
    } else {
      formattedMedia.ebook = null
    }

    updateMedia(formattedMedia, mediaId, mediaSku)
    setMedia(formattedMedia)
  }

  let url = ''

  if (isEbook) {
    url = readEbookUrl(id, sku)
  } else {
    url = listenAudiobookUrl(id, sku)
  }

  // Decide which button to render
  const buttonProps = isAudiobook
    ? {
        onClick: () => {
          openPopupPlayer(listenAudiobookUrl(id, sku))
        },
      }
    : { to: url, as: Link }

  let actionButton = null

  if (inLibrary || subscribed) {
    actionButton = (
      <ActNowButton
        purchased={purchased}
        buttonProps={buttonProps}
        isAudiobook={isAudiobook}
        isPlus={isPlus && userPlus}
        isFree={isFree}
        isVip={isPlus && userVip}
      />
    )
  } else if (subscribable && (isFree || (userVip && (isVip || isPlus)) || (userPlus && isPlus))) {
    actionButton = (
      <AddToLibrary
        isFree={isFree}
        isPlus={isPlus && userPlus}
        isVip={(isVip || isPlus) && userVip}
        mediaId={media.mediaId}
        sku={media[accessor] && media[accessor].sku}
        style={{ width: '100%', marginTop: '1rem' }}
        updateMedia={handleUpdateMedia}
      />
    )
  } else if (isPlus && !userPlus) {
    actionButton = <LearnMoreButton />
  }

  return (
    <PageLayout>
      <Text as="h1" size="large">
        Deseret Book Library
      </Text>
      <Divider style={{ boxShadow: 'none', borderBottom: '1px solid #d7d7d7' }} />
      <Box
        alignItems={isMediumOrLarger ? 'start' : 'center'}
        justifyContent="start"
        isColumn={!isMediumOrLarger}
      >
        <Box marginRight={2} width={25} minWidth={25} marginBottom={5}>
          <figure className="image has-shadow">
            <img src={media.cover.fullUrl} alt={media.title} />
          </figure>
          {actionButton}
        </Box>
        <Box>
          <Box alignItems="end" justifyContent="start">
            <Text display="inline-block" isBold style={{ fontSize: '1.25rem', lineHeight: 1.2 }}>
              {media.title}
            </Text>
            {isPlus && (
              <Icon
                style={{
                  width: '7rem',
                  minWidth: '7rem',
                  height: '1.25rem',
                  marginLeft: '0.75rem',
                  display: 'inline-block',
                }}
                name="plus"
              />
            )}
          </Box>
          <Text display="block">
            <AuthorLinks authors={media.authors} />
          </Text>
          {isAudiobook && (
            <p>
              <Text isSecondary isItalic>
                Duration:&ensp;
              </Text>
              <span>{formatDuration((audiobook && audiobook.length) || 0)}</span>
            </p>
          )}

          <Divider size="small" style={{ boxShadow: 'none', borderBottom: '1px solid #d7d7d7' }} />
          <div>
            <CMSContent
              dangerouslySetInnerHTML={{ __html: media.description }}
              itsNotDangerousBecause="mediaInfo.description comes directly from the Bookshelf API, and should be trustworthy"
            />
          </div>
          <br />
          <Box alignItems="center">
            <Button
              as="a"
              href={`${process.env.REACT_APP_DESERET_BOOK_BASE_URL}/product/${sku}.html`}
              target="_blank"
              style={{ color: '#00d1b2', borderColor: '#00d1b2' }}
            >
              Learn More
            </Button>
            {inLibrary && (
              <InLibraryBadge
                isFree={isFree}
                isPlus={userPlus}
                isVip={userVip}
                purchased={purchased}
              />
            )}
          </Box>
          {isAudiobook && (
            <Box marginVertical={1}>
              <SocialLinks media={media} />
            </Box>
          )}
        </Box>
      </Box>
    </PageLayout>
  )
}

LandingPage.defaults = {
  match: {
    params: {
      id: '',
      sku: '',
    },
  },
}

LandingPage.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
      sku: propTypes.string,
    }),
  }).isRequired,
}

export default LandingPage
