import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Box, Link, Text } from '@deseretbook/react-ui'
import AuthorLinks from '../media/authorLinks/AuthorLinks'

import './MediaItem.css'
import { detailsUrl } from '../../utils/routes'
import { HeadphonesIcon } from '../../dbWebUI'

/**
 * Custom Hit component to render individual search results
 *
 * @param {object} hit - Result from search
 * @returns {function} Component
 */
const MediaItem = ({ mediaItem }) => {
  const { authors, ebook, audiobook, cover, title } = mediaItem

  const variant = ebook || audiobook

  const classes = classNames('MediaItem')
  const authorsList = authors.map((author) => `${author.firstName} ${author.lastName}`).join(', ')
  const productSku = variant.sku

  const landingPage = detailsUrl(mediaItem.mediaId, productSku)

  return (
    <Box alignItems="start" justifyContent="space-between" className={classes}>
      <Link to={landingPage} href={landingPage}>
        <Box minWidth={3} width={3}>
          <figure className="image has-shadow">
            <img src={cover && cover.thumbnailUrl} alt={title} />
          </figure>
        </Box>
      </Link>
      <Box isColumn marginLeft={0.5} width="80%">
        <Link to={landingPage} href={landingPage}>
          <Text isBold size="small" hasEllipsis title={title}>
            {title}
          </Text>
        </Link>
        <Text isItalic size="small" hasEllipsis title={authorsList}>
          <AuthorLinks authors={authors} />
        </Text>
        {audiobook && (
          <Box width={1} marginTop={0.25}>
            <HeadphonesIcon style={{ fill: '#00d1b2' }} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

MediaItem.defaultProps = {
  mediaItem: {},
}

MediaItem.propTypes = {
  mediaItem: PropTypes.shape({
    mediaId: PropTypes.string,
    authors: PropTypes.arrayOf({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    ebook: PropTypes.shape({}),
    audiobook: PropTypes.shape({}),
    cover: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
}

export default MediaItem
