import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BookshelfLogo } from '../../../dbWebUI/icons'

import './LoadingIndicator.css'

/**
 * Component that indicates something is loading/happening.
 *
 *
 * @returns {function} Component
 */
function LoadingIndicator({ className, isRelative, ...rest }) {
  let classes = classNames('LoadingIndicator', className)

  // Adds relative class to use loading icon within content
  if (isRelative) {
    classes += ' LoadingIndicator--relative'
  }

  return (
    <div className={classes} {...rest}>
      <div>
        <BookshelfLogo />
      </div>
    </div>
  )
}

LoadingIndicator.defaultProps = {
  className: '',
  isRelative: false,
}

LoadingIndicator.propTypes = {
  /** Additional className. */
  className: PropTypes.string,
  isRelative: PropTypes.bool,
}

export default LoadingIndicator
