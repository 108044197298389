import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import UnorderedListIcon from '@deseretbook/icons/react/unordered-list'
import GridIcon from '@deseretbook/icons/react/grid'
import { Box, Select } from '@deseretbook/react-ui'
import { Level, Button, Tabs } from '../../../dbWebUI'
import MediaGrid from './MediaGrid'
import MediaList from '../mediaList/MediaList'
import { ViewOptions } from '../mediaCard/ViewOptions'
import { VIEW_OPTIONS, TABS } from '../constants'
import Paginator from '../../buttons/Paginator'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator'

/**
 * A `MediaGrid` component with added filter options.
 *
 * @todo Write tests for this
 * @todo Add story for this
 */
const FilteredMediaGrid = ({
  media,
  updateSortMethod,
  sortMethod,
  currentPage,
  totalPages,
  updatePage,
  tab,
  toggleTab,
  updateItemsPerPage,
  itemsPerPage,
  totalCount,
  isLoading,
  ...rest
}) => {
  const defaultMediaView = localStorage.getItem('mediaView') || VIEW_OPTIONS.grid
  const [view, setView] = useState(defaultMediaView)
  const [ebooks, setEbooks] = useState([])
  const [audiobooks, setAudiobooks] = useState([])
  const currentMedia = tab === TABS.ebook ? ebooks : audiobooks

  /**
   * Changes the current view
   * @param {string} newView View to use. Can be list or grid
   */
  const toggleView = (newView) => {
    localStorage.setItem('mediaView', newView)
    setView(newView)
  }

  // Set ebook and audiobook lists from media
  useEffect(() => {
    const newEbooks = []
    const newAudiobooks = []
    const ebookIds = new Set() // Set to track unique IDs for ebooks
    const audiobookIds = new Set() // Set to track unique IDs for audiobooks

    media.forEach((mediaItem) => {
      if (mediaItem.ebook) {
        const ebookItem = Object.assign({}, mediaItem)

        delete ebookItem.audiobook

        // Check for duplicates using unique ID
        if (!ebookIds.has(ebookItem.mediaId)) {
          ebookIds.add(ebookItem.mediaId)
          newEbooks.push(ebookItem)
        }
      }

      if (mediaItem.audiobook) {
        const audiobookItem = Object.assign({}, mediaItem)

        delete audiobookItem.ebook

        // Check for duplicates using unique ID
        if (!audiobookIds.has(audiobookItem.mediaId)) {
          audiobookIds.add(audiobookItem.mediaId)
          newAudiobooks.push(audiobookItem)
        }
      }
    })

    setEbooks(newEbooks)
    setAudiobooks(newAudiobooks)
  }, [media])

  const listViewButtons = (
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <Button
        style={{ marginRight: '0.5rem' }}
        onClick={() => {
          toggleView(VIEW_OPTIONS.list)
        }}
        title="List View"
        aria-label="List View"
      >
        <UnorderedListIcon
          color={view === 'list' ? '#00d1b2' : 'inherit'}
          width="2rem"
          height="2rem"
        />
      </Button>
      <Button
        aria-label="Grid View"
        title="Grid View"
        onClick={() => {
          toggleView(VIEW_OPTIONS.grid)
        }}
      >
        <GridIcon color={view === 'grid' ? '#00d1b2' : 'inherit'} width="1.5rem" height="1.5rem" />
      </Button>
    </div>
  )

  return (
    <div {...rest} style={{ position: 'relative' }}>
      <ViewOptions
        currentView={view}
        toggleView={toggleView}
        currentSort={sortMethod}
        updateSort={updateSortMethod}
      />
      {listViewButtons}
      <Tabs isLeft>
        <Tabs.Tab isActive={tab === TABS.ebook}>
          <Tabs.Label onClick={() => toggleTab(TABS.ebook)} to="#">
            <Tabs.Title>Ebooks</Tabs.Title>
          </Tabs.Label>
        </Tabs.Tab>
        <Tabs.Tab isActive={tab === TABS.audiobook}>
          <Tabs.Label onClick={() => toggleTab(TABS.audiobook)} to="#">
            <Tabs.Title>Audiobooks</Tabs.Title>
          </Tabs.Label>
        </Tabs.Tab>
      </Tabs>
      {isLoading ? (
        <LoadingIndicator isRelative />
      ) : (
        <>
          <Level>
            <Level.Left>
              <Level.Item>
                <div className="is-fullwidth">
                  Displaying {currentMedia.length * currentPage} of {totalCount}
                </div>
              </Level.Item>
            </Level.Left>
          </Level>
          {view === 'grid' ? (
            <MediaGrid media={currentMedia} mediaType={tab} />
          ) : (
            <MediaList media={currentMedia} mediaType={tab} />
          )}

          <Box
            alignItems="center"
            justifyContent="space-between"
            style={{ margin: '1rem 0', padding: '1rem 0' }}
          >
            <Box alignItems="center">
              Show
              <Select
                style={{ padding: '0 2rem 0 0.5rem' }}
                value={itemsPerPage}
                options={[42, 72, 102]}
                removeHint
                fieldProps={{ style: { margin: '0 0.5rem' } }}
                onChange={(e) => {
                  updateItemsPerPage(e.target.value)
                }}
              />
              items per page
            </Box>
            <Box width={22}>
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={updatePage}
              />
            </Box>
          </Box>
        </>
      )}
    </div>
  )
}

FilteredMediaGrid.defaultProps = {
  media: [],
  perPage: 36,
  sortMethod: '',
  updateSortMethod: () => {},
  ebooks: [],
  audiobooks: [],
  currentPage: 0,
  totalPages: 0,
  updatePage: () => {},
  tab: 'ebook',
  toggleTab: () => {},
  itemsPerPage: 42,
  updateItemsPerPage: () => {},
  totalCount: 0,
  isLoading: false,
}

FilteredMediaGrid.propTypes = {
  /** Media objects to display as `MediaCard`s */
  media: PropTypes.arrayOf({}),
  /** Number of results to display per page when paginating. */
  perPage: PropTypes.number,
  /** The current sorting method */
  sortMethod: PropTypes.string,
  /** Method to update the current sort method */
  updateSortMethod: PropTypes.func,
  /** Ebook media items */
  ebooks: PropTypes.arrayOf({}),
  /** Audiobook media items */
  audiobooks: PropTypes.arrayOf({}),
  /** Pagination */
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  updatePage: PropTypes.func,
  tab: PropTypes.oneOf(['ebook', 'audiobook']),
  toggleTab: PropTypes.func,
  itemsPerPage: PropTypes.number,
  updateItemsPerPage: PropTypes.func,
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default FilteredMediaGrid
