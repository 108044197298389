/**
 * Checks whether a subscription id is within a media accessor
 * @param {object} accessor ebook or audiobook object
 * @param {array} subscriptionIds - ids that match a subscription
 * @returns {boolean} whether the subscription ids are found
 */
const checkMediaSubscription = (accessor, subscriptionIds = []) => {
  return !!(
    accessor &&
    Array.isArray(accessor.subscriptionPlanIds) &&
    accessor.subscriptionPlanIds.find((val) => subscriptionIds.indexOf(val) > -1)
  )
}

export default checkMediaSubscription
