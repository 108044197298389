import React, { useContext, useEffect, useState } from 'react'
import { Box, Collapse } from '@deseretbook/react-ui'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { InputField } from '../../dbWebUI'
import PageLayout from '../../layouts/DefaultLayout'
import ErrorComponent from '../ErrorComponent'
import useDropdown from '../../hooks/useDropdown'
import MediaItem from './MediaItem'

import './SearchBar.css'
import { SearchContext } from './SearchProvider'

/**
 * Search Input component to search for products.
 *
 * @returns {function} Component
 */
const SearchBar = () => {
  const [inputQuery, setInputQuery] = useState('')
  const history = useHistory()
  const url = new URL(window.location.href).pathname || ''
  const { results, setQuery, error, pagination, queryRef, isLoading } = useContext(SearchContext)
  const disable = url.includes('/search')

  const dropdown = useDropdown()

  const classes = classNames('Search')

  /**
   * Update search query and trigger search
   *
   * @param {object} event - Input event
   */
  const updateSearch = (event) => {
    const newQuery = event.target.value

    setInputQuery(newQuery)

    if (!disable) {
      setQuery(newQuery)
    }
  }

  /**
   * Handle key press events
   *
   * @param {object} event - Key press event
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      history.push(`/search?q=${inputQuery}&page=1`)
      dropdown.dropdownProps.close()
    }
  }

  useEffect(() => {
    if (!disable) {
      pagination.updatePage(1)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoading && !disable && queryRef === inputQuery) {
      dropdown.open()
    }
    // eslint-disable-next-line
  }, [isLoading, queryRef, inputQuery])

  if (error) {
    return (
      <PageLayout>
        <ErrorComponent error={error} />
      </PageLayout>
    )
  }

  return (
    <Box style={{ position: 'relative' }} className={classes}>
      <InputField
        {...dropdown.triggerProps}
        onClick={() => {
          if (url.includes('/search') || queryRef !== inputQuery) {
            dropdown.close()
          } else {
            dropdown.open()
          }
        }}
        onBlur={dropdown.close}
        type="search"
        name="title"
        placeholder="Search Catalog and Library"
        value={inputQuery}
        onChange={updateSearch}
        onKeyPress={handleKeyPress}
        fieldProps={{ isFullwidth: true }}
        style={{ minWidth: '20rem' }}
      />
      <Collapse
        {...dropdown.dropdownProps}
        isCollapsed={!dropdown.isOpen}
        style={{
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          maxHeight: '20rem',
          zIndex: 100,
          position: 'absolute',
          top: 50,
          left: 0,
        }}
      >
        <Box width={20}>
          {results.map((mediaItem) => (
            <MediaItem key={mediaItem} mediaItem={mediaItem} />
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}

export default SearchBar
