import { useState, useEffect, useRef } from 'react'
import { getMediaByAuthor, searchMediaTitles } from '../api/search'
import updatePageParam from '../utils/updatePageParam'

/**
 * Custom hook for searching media titles or authors.
 *
 * @param {string} searchType - The type of search to perform. mediaTitle or author
 * @param {object} options - Additional options for the search.
 *
 * @returns {object} searchResults, error, setQuery
 */
const useSearch = (searchType = '', options = {}) => {
  const isMediaTitle = searchType === 'mediaTitle'
  const defaultItemsPerPage = Number(localStorage.getItem('itemsPerPage')) || options.size || 10
  const defaultPage = Number(new URLSearchParams(window.location.search).get('page')) || 1

  // Alternative states
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingAll, setIsLoadingAll] = useState(false)
  const [loadedAll, setLoadedAll] = useState(false)
  const [tryAgain, setTryAgain] = useState(false)

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage)
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [totalPages, setTotalPages] = useState(0)

  // Search query and results
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [paginatedResults, setPaginatedResults] = useState([])
  const [debounce, setDebounce] = useState(null)
  const url = new URL(window.location.href).pathname || ''
  const shouldLoadAll = url.includes('/search') || url.includes('/author')

  // Use ref to track the current query
  const queryRef = useRef(query)

  // ***************** PAGINATION *****************
  /**
   * Paginates the returned results.
   *
   * @param {array} allResults - The results to paginate.
   * @param {number} page - The current page number.
   */
  const paginateResults = (allResults, page) => {
    const startIndex = (page - 1) * itemsPerPage
    const paginated = allResults.slice(startIndex, startIndex + itemsPerPage)

    setPaginatedResults(paginated)
  }

  /**
   * Set the current page to the specified page number.
   *
   * @param {number} pageNumber - The page number to set as the current page.
   * @param {boolean} force - Whether to force the page number.
   */
  const updatePage = (pageNumber, force = false) => {
    const page = Math.max(1, force ? pageNumber : Math.min(pageNumber, totalPages))

    setCurrentPage(page)

    if (shouldLoadAll) {
      updatePageParam(page)

      // Scroll back to the top of the page
      window.scrollTo(0, 0)
    }
  }

  /**
   * Set the number of items per page.
   *
   * @param {number} count - The number of items per page.
   */
  const setItemsPerPageCount = (count) => {
    localStorage.setItem('itemsPerPage', count)
    setItemsPerPage(count)
    setTotalPages(Math.ceil(totalResults / count))
    updatePage(1) // Reset to first page whenever items per page changes
  }

  // ***************** SEARCH *****************

  /**
   * We can easily hit the rate limit for the search API if the user if performing multiple searches or by navigating quickly. To prevent this, we will wait for 2 seconds before trying again.
   *
   * @param {object} e - The error object
   */
  const handleErrorResponse = async (e) => {
    if (!tryAgain) {
      // Wait for 2 seconds before we try again
      await new Promise((resolve) => setTimeout(resolve, 2000))
      setTryAgain(true)
    } else {
      // Looks like we encountered an error again. Let's show the error message.
      setTryAgain(false)
      setError(e)
    }
  }

  /**
   * Handles the response object from the search API
   *
   * @param {object} response - The response object from the search API
   * @param {boolean} isAll - Whether the response is for all results
   */
  const handleSearchResults = (response, isAll) => {
    // Prevent longer queries from overwriting the current query
    if (query !== queryRef.current) {
      return
    }

    const { pagination = {}, results: newResults = [] } = response
    const allResults = []

    const filteredResults = newResults.filter(
      (mediaItem) => mediaItem && (mediaItem.ebook || mediaItem.audiobook),
    )

    // Iterate through returned results and split media with ebook and audiobook
    filteredResults.forEach((mediaItem = {}) => {
      const { ebook, audiobook } = mediaItem

      if (ebook && audiobook) {
        const ebookMedia = { ...mediaItem, audiobook: undefined }
        const audiobookMedia = { ...mediaItem, ebook: undefined }

        allResults.push(ebookMedia, audiobookMedia)
      } else {
        allResults.push(mediaItem)
      }
    })

    setResults(allResults)
    paginateResults(allResults, currentPage)

    const urlPage = Number(new URLSearchParams(window.location.search).get('page'))

    if (isAll) {
      setTotalResults(allResults.length)
      const allTotalPages = Math.ceil(allResults.length / itemsPerPage)

      setTotalPages(allTotalPages)

      // Update the page if it is beyond the total pages
      if (allTotalPages && urlPage > allTotalPages) {
        updatePage(allTotalPages, true)
      }
    } else {
      setTotalResults(Math.max(allResults.length, pagination.total))

      updatePage(urlPage, true)
    }
  }

  /**
   * Fetches all results for a given author
   */
  const handleAllResults = async () => {
    // Prevent longer queries from overwriting the current query
    if (queryRef.current && queryRef.current !== query) {
      return
    }

    try {
      const response = isMediaTitle
        ? await searchMediaTitles({
            query,
            contentSet: 'catalog',
            size: Math.min(totalResults, 500),
          })
        : await getMediaByAuthor({
            authorId: options.authorId,
            size: Math.min(totalResults, 500),
          })

      handleSearchResults(response, true)
      setLoadedAll(true)
    } catch (e) {
      handleErrorResponse(e)
    }
    setIsLoadingAll(false)
  }

  /**
   * Performs search
   */
  const handleSearch = async () => {
    try {
      if (isMediaTitle && (!query || query.length < 3)) {
        setResults([])
        setPaginatedResults([])
        setTotalPages(0)
      } else {
        const response = isMediaTitle
          ? await searchMediaTitles({
              query,
              contentSet: 'catalog',
              size: itemsPerPage * 2,
            })
          : await getMediaByAuthor({
              authorId: options.authorId || query,
              size: itemsPerPage * 2,
            })

        handleSearchResults(response, false)
        setTotalPages(response.pagination.lastPage * 2)

        if (shouldLoadAll && options.loadAll) {
          setIsLoadingAll(true)
        }
      }
      setIsLoading(false)
      setTryAgain(false)
    } catch (e) {
      handleErrorResponse(e)
    }
  }

  /**
   * Determine if provided media matches the mediaId and sku.
   *
   * @param {object} media - The media object to check.
   * @param {number} id - The id of the media to match.
   * @param {string} sku - The sku of the media to match.
   *
   * @returns {boolean} Whether the media matches the id and sku.
   */
  const isMatch = (media, id, sku) =>
    media.mediaId === id &&
    ((media.ebook && media.ebook.sku === sku) || (media.audiobook && media.audiobook.sku === sku))

  /**
   * Helper function to update media in a given array.
   *
   * @param {Array} array - The array to update.
   * @param {Function} setArrayFunction - The setter function for the array.
   * @param {object} updatedMedia - The updated media object.
   * @param {number} id - The id of the media to match.
   * @param {string} sku - The sku of the media to match.
   */
  const updateMediaInArray = (array, setArrayFunction, updatedMedia, id, sku) => {
    const index = array.findIndex((media) => isMatch(media, id, sku))

    if (index !== -1) {
      setArrayFunction([...array.slice(0, index), updatedMedia, ...array.slice(index + 1)])
    }
  }

  /**
   * Update a result in the list of results.
   *
   * @param {object} updatedMedia - The updated media object.
   * @param {number} id - The id of the result to update.
   * @param {string} sku - The sku of the result to update.
   */
  const updateMedia = (updatedMedia, id, sku) => {
    // Update media in results
    updateMediaInArray(results, setResults, updatedMedia, id, sku)

    // Update media in paginatedResults
    updateMediaInArray(paginatedResults, setPaginatedResults, updatedMedia, id, sku)
  }

  /**
   * Debounce the search query to prevent multiple API calls.
   */
  useEffect(() => {
    clearTimeout(debounce)

    const timeoutId = setTimeout(() => {
      queryRef.current = query
      setIsLoading(true)
    }, 300)

    setDebounce(timeoutId)

    // eslint-disable-next-line
  }, [query])

  /**
   * Fetch initial results when the query changes.
   */
  useEffect(() => {
    if ((isLoading || tryAgain) && !options.disable) {
      handleSearch()
    }
    // eslint-disable-next-line
  }, [isLoading, tryAgain])

  /**
   * Fetch all results after the initial search.
   */
  useEffect(() => {
    if (isLoadingAll) {
      setLoadedAll(false)
      handleAllResults()
    }
    // eslint-disable-next-line
  }, [isLoadingAll])

  /**
   * Update paginated results whenever the loading state changes.
   */
  useEffect(() => {
    paginateResults(results, currentPage)
    // eslint-disable-next-line
  }, [currentPage, itemsPerPage])

  return {
    updateMedia,
    query,
    queryRef: queryRef.current,
    results: paginatedResults,
    totalResults,
    itemsPerPage,
    setItemsPerPageCount,
    isLoading,
    setIsLoading,
    isLoadingAll,
    setIsLoadingAll,
    loadedAll,
    error,
    setQuery,
    pagination: {
      totalPages,
      updatePage,
      currentPage,
    },
  }
}

export default useSearch
